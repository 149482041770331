 
<template>
  <div class="veterans">
      <van-form @submit="onSubmit" validateTrigger="onSubmit">
        <h3>基本信息</h3>
        <van-field v-model="formData.realName" name="realName" label="姓名" placeholder="姓名" :rules="[{ required: true}]" disabled>
            <template #left-icon>
               <van-icon name="user-o" />
            </template>
        </van-field> 
        <van-field v-model="formData.account" name="account" label="身份证号" placeholder="身份证号" :rules="[{ validator: isMobile, message: '请输入正确身份证号' }]" disabled>
            <template #left-icon>
               <van-icon name="phone-o" />
            </template>
        </van-field>
        <van-field v-model="formData.phone" name="phone" label="手机号" placeholder="手机号" :rules="[{ validator: isMobile, message: '请输入正确手机号' }]">
            <template #left-icon>
               <van-icon name="phone-o" />
            </template>
        </van-field>

        <!-- <h3>服役信息</h3>
        <van-field v-model="formData.armyStartTime" name="入伍时间" label="入伍时间" placeholder="点击选择入伍时间" readonly clickable @click="showCalendarFn('armyStartTime')"  :rules="[{ validator: dateValidator, message: '退伍时间应小于入伍时间' }]" >
            <template #left-icon>
               <van-icon name="clock-o" />
            </template>
            <template #right-icon>
               <van-icon name="calendar-o" />
            </template>
        </van-field>
        <van-datetime-picker
            @cancel='dateType=""'
            @confirm='onCalendarConfirm'
            v-if="dateType=='armyStartTime'"
            class="armyStartTime-action"
            type="date"
            title="请选择入伍时间"
            :min-date="minDate"
            :max-date="maxDate"
            />
        <van-field v-model="formData.armyEndTime" name="退伍时间" label="退伍时间" placeholder="点击选择退伍时间" readonly clickable @click="showCalendarFn('armyEndTime')" :rules="[{ validator: dateValidator, message: '退伍时间应小于入伍时间' }]" >
            <template #left-icon>
               <van-icon name="clock-o" />
            </template>
            <template #right-icon>
               <van-icon name="calendar-o" />
            </template>
        </van-field>
        <van-datetime-picker
            @cancel='dateType=""'
            @confirm='onCalendarConfirm'
            v-if="dateType=='armyEndTime'"
            class="armyEndTime-action"
            type="date"
            title="请选择退伍时间"
            :min-date="minDate"
            :max-date="maxDate"
            />
        <van-field v-model="formData.armyYear" name="服役年限" label="服役年限" placeholder="服役年限" readonly :rules="[{ required: true}]">
            <template #left-icon>
               <van-icon name="tosend" />
            </template>
        </van-field> -->
        <!-- <van-field v-model="formData.armyJob" name="军队职务" label="军队职务" placeholder="军队职务" :rules="[{ required: true}]">
            <template #left-icon>
               <van-icon name="phone-o" />
            </template>
        </van-field> -->

        <div style="margin: 16px;">
            <van-button  block type="info" native-type="submit">提交</van-button>
        </div>
        </van-form>
  </div>
</template>

<script> 
import { dateFormat  } from "@/utils/date"
import { veteranSubmit  } from "@/api/veteransApi"
import { isMobile } from '@/utils/validate'
export default {
  name: 'InforEntry',
  components: {
  }, 
  data() {
    return {
        formData: {
            realName:  "", 
            account: "",
            phone: "",
            // armyStartTime:  "",
            // armyEndTime:  "",
            // armyYear: 0, 
        },
        dateType: "",
        minDate: new Date(1930, 0, 1),
        maxDate: new Date(),
        showCalendar:false
    }
  },
  // 计算属性
  computed: {
      armyYear:{
          get:function(){
              var armyYear = 0
            if(this.formData.armyStartTime&&this.formData.armyEndTime){
                var t1 = new Date(this.formData.armyEndTime).getTime()-new Date(this.formData.armyStartTime).getTime()
                if(t1<=0){
                    armyYear = 0
                    return armyYear
                }
                armyYear  = (t1/(1000*60*60*24*365)).toFixed()
            }else{
                armyYear = 0
            }
            return armyYear
        },
        set:function(){}
      }
  },

  // watch
  watch: {
      "armyYear":{//深度监听，可监听到对象、数组的变化
         handler(val){
            this.formData.armyYear = val
         },
     }
  },

  // 创建完成（可以访问当前this实例）
  created() {
    this.formData.realName = this.$store.getters.userInfo.realName 
    this.formData.account = this.$store.getters.userInfo.account
    this.formData.phone = this.$store.getters.userInfo.phone
    // this.formData.armyStartTime = this.$store.getters.userInfo.armyStartTime ? /\d{4}-\d{1,2}-\d{1,2}/g.exec(this.$store.getters.userInfo.armyStartTime)[0] : ''
    // this.formData.armyEndTime = this.$store.getters.userInfo.armyEndTime ? /\d{4}-\d{1,2}-\d{1,2}/g.exec(this.$store.getters.userInfo.armyEndTime)[0] : ''
    // this.formData.armyYear = this.$store.getters.userInfo.armyYear > 0 ? this.$store.getters.userInfo.armyYear : 0
  },

  // 挂载完成（可以访问DOM元素）
  mounted() {},
 

  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  // 方法集合
  methods: {
    isMobile: isMobile,
    onSubmit(val) {
      let data = {
        openId: this.$store.getters.userId, 
        armyStartTime: this.formData.armyStartTime + ' 00:00:00',
        armyEndTime: this.formData.armyEndTime + ' 00:00:00',
        armyYear: this.formData.armyYear,
 
      }
      veteranSubmit(data).then(res => {
        if (res.code === 200) {
            this.$store.dispatch("GetInfo").then(()=> {
                let _this = this
                this.$toast({
                    type: 'success',
                    message: '添加成功',
                    duration: 500,
                    onClose: function() {
                        _this.$router.go(-1) 
                    }
                })   
            })
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }, 
    onCalendarConfirm(date){
      if(this.dateType =='armyEndTime'){
          if (this.formData.armyStartTime && new Date(this.formData.armyStartTime) > new Date(date)) {
              this.$toast.fail('退伍时间应大于入伍时间！')
              return 
          } 
          this.formData.armyEndTime=dateFormat(date,"YYYY-MM-DD")
      }else if(this.dateType =='armyStartTime'){
          if (this.formData.armyStartTime && new Date(this.formData.armyStartTime) < new Date(date)) {
              this.$toast.fail('入伍时间应小于退伍时间！')
              return 
          } 
          this.formData.armyStartTime=dateFormat(date,"YYYY-MM-DD")
      }
      this.dateType=''
    },
      
    showCalendarFn(dateType) {
      if(dateType){
        this.dateType= dateType
      }
    },
    dateValidator(){
        if(this.formData.armyStartTime&&this.formData.armyEndTime){
          var t1 = new Date(this.formData.armyEndTime).getTime()-new Date(this.formData.armyStartTime).getTime()
          if(t1<=0){
              return false
          }
          return t1
        }else{
          return false
        }
    }
  }
}
</script>

<style lang='scss'>
</style>
<style lang='scss' scoped>
.veterans{
    background: #f3f4f6;
    h3{
        color: #8d8c8c;
        font-size: 16px;
        font-weight: 300;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
    }
    .armyStartTime-action,.armyEndTime-action{
        position: absolute;
        left: 0%;
        bottom: 0;
        width: 100%;
        z-index: 99;
    }
}
</style>
