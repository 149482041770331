import axios from '@/utils/axios';
const baseURL = '/api'
// 退役军人活动

// 列表
export const getVeteransList = (params) => {
  return axios({
    baseURL,
    url: '/app/convenient/activity/page',
    method: 'get',
    params
  });
};
// 详情
export const getVeteransDetail = (params) => {
  return axios({
    baseURL,
    url: '/app/convenient/activity/detail',
    method: 'get',
    params
  });
};
// 军人活动报名
export const veteransEnroll = (params) => {
  return axios({
    baseURL,
    url: '/app/convenient/activity/enroll',
    method: 'post',
    params
  });
};
// 军人 录入
export const veteranSubmit = (data) => {
  return axios({
    baseURL,
    url: '/app/convenient/veteran/submit',
    method: 'post',
    data
  });
};